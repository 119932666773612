import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { PlusIcon } from 'components/icons/Plus'
import { useRepository } from 'hooks/auth'
import { useCubitBuilder } from 'hooks/cubits'
import { Contents, MainPane, Header, Actions } from 'pages/layout/TwoColumn'
import { useTranslation } from 'react-i18next'
import { InstructorAssistantsCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorAssistantsCubit'
import { AddAssistantDialog } from './AddAssistantDialog'
import { useDialogs } from 'hooks/dialogs'
import { observer } from 'mobx-react-lite'
import { BreakoutTable } from 'components/design-system/BreakoutTable'
import { XSmallIcon } from 'components/icons/XSmall'
import { RemoveAssistantDialog } from './RemoveAssistantDialog'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import { useRootStore } from 'hooks/rootStore'
import { ImpersonationInfo } from 'components/ImpersonationInfo'

export const InstructorAssistantsPage = observer(
  function InstructorAssistantsPage() {
    const repository = useRepository()
    const rootStore = useRootStore()
    const instructorUserId = rootStore.impersonatedUserId
    const cubit = useCubitBuilder(() => {
      return new InstructorAssistantsCubit(repository, {
        instructorUserId,
      })
    }, [repository, instructorUserId])
    const { showDialog } = useDialogs()

    const { t } = useTranslation()
    const hasAssistants =
      cubit.assistants.isLoaded && cubit.assistants.length > 0
    return (
      <MainPane>
        <Header>
          <h1 className="text-display-medium font-display">
            {t('instructor_assistants.headline')}
          </h1>
          <ImpersonationInfo />
          <Actions>
            {hasAssistants && (
              <BreakoutButton
                onClick={() => {
                  showDialog(() => <AddAssistantDialog cubit={cubit} />)
                }}
                kind="secondary"
                size="large"
                icon={<PlusIcon />}
                title={t('instructor_assistants.create_assistant_invitation')}
              >
                {t('instructor_assistants.create_assistant_invitation')}
              </BreakoutButton>
            )}
          </Actions>
        </Header>
        <Contents className="h-full">
          {cubit.assistants.isLoading && <div>Loading...</div>}
          {cubit.assistants.isLoaded && cubit.assistants.length === 0 && (
            <EmptyState cubit={cubit} />
          )}
          {hasAssistants && <List cubit={cubit} />}
        </Contents>
      </MainPane>
    )
  }
)

const List = observer(function List({
  cubit,
}: {
  cubit: InstructorAssistantsCubit
}) {
  const { showDialog } = useDialogs()
  const { t } = useTranslation()
  return (
    <div>
      <BreakoutTable>
        <thead>
          <tr>
            <th colSpan={2}>Full name</th>
          </tr>
        </thead>
        <tbody>
          {cubit.assistants.models.map((assistant) => (
            <tr key={assistant.id}>
              <td>
                <div className="flex items-center gap-2">
                  <BreakoutUserAvatar user={assistant} radius={20} />
                  <strong className="text-label-medium">
                    {assistant.fullName}
                  </strong>
                </div>
              </td>
              <td className="text-right">
                <BreakoutButton
                  size="large"
                  kind="error"
                  title={t('instructor_assistants.remove_assistant')}
                  icon={<XSmallIcon />}
                  onClick={() => {
                    showDialog(() => (
                      <RemoveAssistantDialog
                        cubit={cubit}
                        assistantId={assistant.id}
                      />
                    ))
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </BreakoutTable>
    </div>
  )
})

function EmptyState({ cubit }: { cubit: InstructorAssistantsCubit }) {
  const { t } = useTranslation()
  const { showDialog } = useDialogs()
  return (
    <div className="flex h-full w-full flex-col items-center justify-center rounded-2xl bg-surface p-5">
      <div className="text-body-medium mb-10 max-w-[320px] text-center">
        {t('instructor_assistants.teachers_assistants_welcome')}
      </div>
      <BreakoutButton
        onClick={() => {
          showDialog(() => <AddAssistantDialog cubit={cubit} />)
        }}
        size="large"
        icon={<PlusIcon />}
      >
        {t('instructor_assistants.create_assistant_invitation')}
      </BreakoutButton>
    </div>
  )
}
