import { type AssignmentGroupData } from '@breakoutlearning/firebase-repository/cubits/InstructorAssignmentCubit'
import { RoomStateStatus } from '@breakoutlearning/firebase-repository/models/RoomState'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import { RubricScoreWidget } from 'components/breakout/RubricScoreWidget'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutTable } from 'components/design-system/BreakoutTable'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { DownloadIcon } from 'components/icons/Download'
import { EyeOpen } from 'components/icons/EyeOpen'
import { PeopleGroup } from 'components/icons/PeopleGroup'
import { Person } from 'components/icons/Person'
import { Search } from 'components/icons/Search'
import { Shield } from 'components/icons/Shield'
import { SparklesIcon } from 'components/icons/Sparkles'
import { TrashCanIcon } from 'components/icons/TrashCan'
import { useRepository } from 'hooks/auth'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { useDialogs } from 'hooks/dialogs'
import { useBreakoutUser } from 'hooks/profile'
import { useRootStore } from 'hooks/rootStore'
import { useSettings } from 'hooks/settings'
import { observer } from 'mobx-react-lite'
import { AssignmentRubricGroupDialog } from 'pages/instructor/dialogs/AssignmentRubricGroupDialog'
import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { GroupRemoveUserDialog } from './dialogs/GroupRemoveUserDialog'
import { GroupAddUserDialog } from './dialogs/GroupAddUserDialog'
import { downloadAssignmentGroupDataAsCSV } from 'util/csv'
import { SummaryDialog } from 'pages/student/assignment/dialogs/SummaryDialog'
import { EmptyState } from 'components/breakout/EmptyState'
import { PeopleMultiple } from 'components/icons/PeopleMultiple'
import { BreakoutPill } from 'components/design-system/BreakoutPill'
import { MeetingResultsDialog } from 'pages/student/assignment/dialogs/MeetingResultsDialog'
import type { RoomStateAnswer } from '@breakoutlearning/firebase-repository/models/RoomStateAnswer'

export const InstructorAssignmentStudents = observer(function StudentsView() {
  const [addingGroup, setAddingGroup] = useState(false)
  const currentUser = useBreakoutUser()
  const rootStore = useRootStore()
  const cubit = useInstructorAssignmentCubit()
  const data = cubit.assignmentGroupDataSortedWithNotInGroup
  const input = useMemo(() => createRef<HTMLInputElement>(), [])
  const { showDialog } = useDialogs()
  const { t } = useTranslation()
  const tScoped = useCallback(
    (key: string, options?: Record<string, unknown>) =>
      t(`instructor_assignment.${key}`, options),
    [t]
  )

  useLayoutEffect(() => {
    // we need to focus only after the animation is done
    setTimeout(() => {
      input.current?.focus()
    }, 300)
  }, [input])

  // Groups with all empty groups merged into one.
  const groups = useMemo(() => {
    // Separate the groups into empty and non-empty groups
    const emptyGroups = data.filter((group) => group.roomState?.isEmpty)
    const nonEmptyGroups = data.filter(
      (group) => group.roomState?.isNotEmpty && group.groupMembers.length > 0
    )
    const groupsWithNoUsers = cubit.assignmentGroupDataForRoomsWithNoUsers

    if (emptyGroups.length > 0) {
      const mergedEmptyGroup = emptyGroups[0]
      mergedEmptyGroup.groupMembers = emptyGroups.flatMap(
        (group) => group.groupMembers
      )
      return [...nonEmptyGroups, ...groupsWithNoUsers, mergedEmptyGroup]
    }

    // If there are no empty groups, return only non-empty groups
    return [...nonEmptyGroups, ...groupsWithNoUsers]
  }, [cubit.assignmentGroupDataForRoomsWithNoUsers, data])

  if (!groups.length && !cubit.userFilters.length) {
    return (
      <EmptyState Icon={PeopleMultiple} text={tScoped('students_missing')} />
    )
  }

  return (
    <div className="overflow-auto rounded-2xl bg-surface p-5 md:h-full md:overflow-hidden">
      <div className="h-full overflow-auto rounded-2xl bg-core-tertiary">
        <BreakoutTable className="white h-auto">
          <thead>
            <tr>
              <th>{tScoped('group')}</th>
              <th>{tScoped('full_name')}</th>
              <th>{tScoped('quiz_results')}</th>
              <th>{tScoped('rubric_assessment')}</th>
              <th>
                <BreakoutTextInput
                  ref={input}
                  name="filter"
                  type="text"
                  className="min-w-[130px]"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      const name = e.currentTarget.value
                      cubit.addUserFilter(name)
                      e.currentTarget.value = ''
                    }
                  }}
                  LeadingIcon={Search}
                  kind="tertiary"
                  placeholder={t('instructor_assignment.filter_names')}
                />
              </th>

              <th>
                <div className="flex items-center justify-between gap-1">
                  <div>
                    <BreakoutButton
                      kind="secondary"
                      variant="outlined"
                      size="small"
                      className="shrink-0"
                      disabled={addingGroup}
                      loading={addingGroup}
                      aria-label={t('instructor_assignment.add_group')}
                      onClick={async () => {
                        setAddingGroup(true)
                        await cubit.createRoomState()
                        setAddingGroup(false)
                      }}
                    >
                      {t('instructor_assignment.add_group')}
                    </BreakoutButton>
                  </div>
                  <BreakoutButton
                    kind="tertiary"
                    size="small"
                    aria-label={t('instructor_assignment.download_csv')}
                    onClick={() => {
                      downloadAssignmentGroupDataAsCSV(cubit.repository, data)
                    }}
                    icon={<DownloadIcon size={20} />}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="h-full">
            {cubit.userFilters.length > 0 && (
              <tr>
                <td colSpan={6}>
                  {cubit.userFilters.length > 0 && (
                    <BreakoutPill.Chips
                      labels={cubit.userFilters}
                      onClick={cubit.removeUserFilter}
                    />
                  )}
                </td>
              </tr>
            )}
            {groups?.map((groupData, index) => {
              const sortedGroupMembers = groupData.groupMembers.sort((a, b) => {
                const aUser = cubit.repository.userStore.getUser(a)
                const bUser = cubit.repository.userStore.getUser(b)
                return aUser.fullName.localeCompare(bUser.fullName)
              })

              const canShowSummary =
                groupData.roomState &&
                groupData.roomState.isNotEmpty &&
                groupData.summary &&
                groupData.summary.hasData
              const canShowNewSessionResults =
                groupData.roomState &&
                groupData.roomState.isNotEmpty &&
                cubit.repository.featureFlags.data.sessionResultsUseNew &&
                groupData.roomState.data.roomStartedAt !== undefined &&
                groupData.roomState.isCompleted
              const resultsRequiredDataLoaded =
                cubit.roomStateRubricResultDetails.isLoaded &&
                cubit.roomStateRubricResults.isLoaded &&
                cubit.roomStateAnswers.isLoaded &&
                cubit.roomStateEngagements.isLoaded &&
                cubit.repository.featureFlags.isLoaded &&
                groupData.roomState?.isLoaded
              const showSummaryButton =
                canShowSummary || canShowNewSessionResults

              return (
                <React.Fragment
                  key={groupData.roomState?.id || `index-${index}`}
                >
                  <tr>
                    <td
                      rowSpan={groupData.groupMembers.length + 1}
                      className={
                        'h-fit w-fit flex-1 items-start border-r border-r-surface'
                      }
                      tabIndex={0}
                    >
                      {groupData.roomState?.isEmpty && (
                        <div>
                          <div className="text-title-small">
                            {t('instructor_assignment.not_assigned_to_group')}
                          </div>
                          <div className="text-body-small text-grey-text">
                            {t('instructor_assignment.student_count', {
                              count: groupData.groupMembers.length,
                            })}
                          </div>
                        </div>
                      )}
                      {groupData.roomState?.isNotEmpty && (
                        <div className="flex h-full flex-col items-start justify-center gap-2">
                          <div className="text-title-small">
                            {groupData.roomState?.data.roomStateName}
                          </div>
                          <div>
                            <RoomStateStatusBadge
                              status={groupData.roomStateStatus}
                              userCount={groupData.roomState.userCount}
                            />
                          </div>
                          {groupData.roomStateStatus === RoomStateStatus.live &&
                            !groupData.summary?.id && (
                              <BreakoutButton
                                className="whitespace-nowrap"
                                aria-label={t(
                                  'instructor_assignment.monitor_session'
                                )}
                                onClick={async () => {
                                  if (!groupData.roomState) return
                                  const result = await cubit.observeRoom(
                                    groupData.roomState
                                  )
                                  // if we can observe the room, navigate to the room
                                  if (result) {
                                    rootStore.navigateTo('meeting', {
                                      roomId: groupData.roomState.id,
                                    })
                                  }
                                }}
                                kind="tertiary"
                                size="small"
                                icon={<EyeOpen size={14} />}
                              >
                                {t('instructor_assignment.monitor_session')}{' '}
                                &gt;
                              </BreakoutButton>
                            )}
                          {showSummaryButton && (
                            <BreakoutButton
                              kind="tertiary"
                              size="small"
                              className="whitespace-nowrap"
                              loading={!resultsRequiredDataLoaded}
                              disabled={!resultsRequiredDataLoaded}
                              icon={<SparklesIcon size={14} />}
                              aria-label={t(
                                'instructor_assignment.show_summary'
                              )}
                              onClick={() => {
                                if (
                                  !groupData.summary ||
                                  !groupData.roomState
                                ) {
                                  return
                                }
                                const summary = groupData.summary
                                const roomState = groupData.roomState
                                const roomAnswers =
                                  cubit.roomStateAnswers.models.filter(
                                    (a) => a.data.roomId === roomState.id
                                  )
                                const roomAnswersPerUser = roomAnswers.reduce(
                                  (acc, answer) => {
                                    const { userId } = answer.data
                                    if (!userId) return acc
                                    if (!acc.has(userId))
                                      return acc.set(userId, [])
                                    acc.get(userId)?.push(answer)
                                    return acc
                                  },
                                  new Map<string, RoomStateAnswer[]>()
                                )
                                showDialog(() => {
                                  if (canShowNewSessionResults) {
                                    return (
                                      <MeetingResultsDialog
                                        slideDeckId={roomState.data.slideDeckId}
                                        roomState={roomState}
                                        roomStateAnswersForGroup={
                                          roomAnswersPerUser.get(
                                            roomState.id
                                          ) || []
                                        }
                                        roomStateAnswersPerUser={
                                          roomAnswersPerUser
                                        }
                                        roomStateEngagement={cubit.roomStateEngagements.models.filter(
                                          (e) => e.data.roomId === roomState.id
                                        )}
                                        rubricResultDetails={cubit.roomStateRubricResultDetails.models.filter(
                                          (r) => r.data.roomId === roomState.id
                                        )}
                                        rubricResults={cubit.roomStateRubricResults.models.filter(
                                          (r) => r.data.roomId === roomState.id
                                        )}
                                        users={roomState.users}
                                      />
                                    )
                                  }
                                  return (
                                    <SummaryDialog
                                      summary={summary.data}
                                      roomStateName={roomState.roomStateName}
                                      roomStateActiveSlideChangedAt={
                                        roomState.data.activeSlideChangedAt ||
                                        null
                                      }
                                    />
                                  )
                                })
                              }}
                            >
                              {t('instructor_assignment.show_summary')} &gt;
                            </BreakoutButton>
                          )}

                          {currentUser.isCorre && groupData.roomState && (
                            <BreakoutButton
                              kind="tertiary"
                              size="small"
                              className="whitespace-nowrap"
                              aria-label={t(
                                'instructor_assignment.student_view'
                              )}
                              onClick={() => {
                                if (!groupData.roomState) return
                                rootStore.navigateTo('assignmentWithRoom', {
                                  assignmentId:
                                    groupData.roomState?.data.assignmentId,
                                  sectionId:
                                    groupData.roomState?.data.sectionId,
                                  roomStateId: groupData.roomState?.id,
                                })
                              }}
                              icon={<PeopleGroup size={14} />}
                            >
                              {t('instructor_assignment.student_view')} &gt;
                            </BreakoutButton>
                          )}
                          {!cubit.section.data.shareable &&
                            !groupData.summary?.id &&
                            groupData.roomStateStatus !==
                              RoomStateStatus.live && (
                              <BreakoutButton
                                kind="tertiary"
                                size="small"
                                aria-label={t(
                                  'instructor_assignment.add_member'
                                )}
                                className="whitespace-nowrap"
                                onClick={() => {
                                  showDialog(() => {
                                    return (
                                      <GroupAddUserDialog
                                        cubit={cubit}
                                        groupData={groupData}
                                      />
                                    )
                                  })
                                }}
                                icon={<Person size={14} />}
                              >
                                {t('instructor_assignment.add_member')} &gt;
                              </BreakoutButton>
                            )}
                        </div>
                      )}
                    </td>
                  </tr>
                  {sortedGroupMembers.map((memberId) => (
                    <UserRow
                      key={memberId}
                      groupData={groupData}
                      userId={memberId}
                    />
                  ))}
                </React.Fragment>
              )
            })}
          </tbody>
        </BreakoutTable>
      </div>
    </div>
  )
})

const UserRow = observer(function UserRow({
  groupData,
  userId,
}: {
  groupData: AssignmentGroupData
  userId: string
}) {
  const cubit = useInstructorAssignmentCubit()
  const repository = useRepository()
  const member = repository.userStore.getUser(userId)
  const rubrics = groupData.rubricResults.get(userId)
  const rubricEntries = Array.from(rubrics?.entries() || [])
  const { t } = useTranslation()

  const { showDialog } = useDialogs()
  const showRubricDialog = useCallback(() => {
    showDialog(() => (
      <AssignmentRubricGroupDialog
        groupData={groupData}
        userId={userId}
        singleUserMode={true}
      />
    ))
  }, [groupData, showDialog, userId])

  const quizResult = `${(((groupData.quizScore.get(userId) || 0) * 1000) / 10).toFixed(1)}%`

  const rowAriaLabel = [
    member.fullName,
    ' - ',
    t('instructor_assignment.quiz_results'),
    ': ',
    quizResult,
    ', ',
    t('instructor_assignment.rubric_assessment'),
    ': ',
    rubricEntries
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_, results]) => results.map((r) => r.data.score))
      .flat()
      .join(', '),
  ].join('')

  return (
    <tr key={userId} tabIndex={0} aria-label={rowAriaLabel}>
      <td>
        <div className="flex flex-row items-center gap-2">
          <div>
            <BreakoutUserAvatar user={member} radius={16} aria-hidden />
          </div>
          <div className="text-label-medium">{member.fullName}</div>
          {groupData.roomState?.data.groupLeaderUserIds.includes(userId) && (
            <Shield size={16} className="stroke-breakout-orange" aria-hidden />
          )}
        </div>
      </td>
      <td>
        {(((groupData.quizScore.get(userId) || 0) * 1000) / 10).toFixed(1)}%
      </td>
      <td colSpan={2}>
        <div
          className="flex cursor-pointer flex-row gap-1"
          onClick={showRubricDialog}
        >
          {rubricEntries.map(([rubric, results], index) => (
            <RubricScoreWidget
              key={rubric.id || `rubric-${index}`}
              rubric={rubric}
              rubricResult={results[0]}
            />
          ))}
        </div>
      </td>
      <td>
        {!cubit.section.data.shareable &&
          groupData.summary?.isEmpty &&
          groupData.roomState?.isNotEmpty && (
            <div className="relative ml-auto lg:max-w-3">
              <BreakoutButton
                size="small"
                kind="tertiary"
                aria-label={[
                  t('instructor_assignment.remove_member'),
                  member.fullName,
                ].join(': ')}
                className="float-right"
                onClick={() => {
                  showDialog(({ remove }) => {
                    const roomState = groupData.roomState
                    if (!roomState) return

                    return (
                      <GroupRemoveUserDialog
                        cubit={cubit}
                        userId={userId}
                        roomState={roomState}
                        onClick={remove}
                      />
                    )
                  })
                }}
                icon={<TrashCanIcon size={12} />}
              ></BreakoutButton>
            </div>
          )}
      </td>
    </tr>
  )
})

function RoomStateStatusBadge({
  status,
  userCount,
}: {
  status: RoomStateStatus
  userCount: number
}) {
  const { t } = useTranslation()
  const { animationsEnabled } = useSettings()
  const animation = animationsEnabled ? 'pulse-animation' : ''
  let text = ''
  let icon = <></>

  if (!userCount) {
    return (
      <strong className="text-body-medium inline-block whitespace-nowrap rounded-xl py-3">
        <span
          className={`mr-1 inline-block h-[8px] w-[8px] rounded-full bg-dark-grey`}
        ></span>
        {t('instructor_assignment.empty')}
      </strong>
    )
  }

  switch (status) {
    case RoomStateStatus.live: {
      text = t('instructor_assignment.room_state_status_live')
      icon = (
        <span
          className={`${animation} mr-1 inline-block h-[8px] w-[8px] rounded-full bg-breakout-green`}
        ></span>
      )
      break
    }
    case RoomStateStatus.completed: {
      text = t('instructor_assignment.room_state_status_completed')
      icon = (
        <span
          className={`mr-1 inline-block h-[8px] w-[8px] rounded-full bg-breakout-green`}
        ></span>
      )
      break
    }
    case RoomStateStatus.abandoned: {
      text = t('instructor_assignment.room_state_status_abandoned')
      icon = (
        <span className="mr-1 inline-block h-[8px] w-[8px] rounded-full bg-breakout-red"></span>
      )
      break
    }
    case RoomStateStatus.scheduled: {
      text = t('instructor_assignment.room_state_status_scheduled')
      icon = (
        <span
          className={`mr-1 inline-block h-[8px] w-[8px] rounded-full bg-breakout-green`}
        ></span>
      )
      break
    }
    case RoomStateStatus.mustSchedule: {
      text = t('instructor_assignment.room_state_status_must_schedule')
      icon = (
        <span
          className={`mr-1 inline-block h-[8px] w-[8px] rounded-full bg-breakout-green`}
        ></span>
      )
      break
    }
    case RoomStateStatus.expired: {
      text = t('instructor_assignment.room_state_status_expired')
      icon = (
        <span
          className={`mr-1 inline-block h-[8px] w-[8px] rounded-full bg-dark-grey`}
        ></span>
      )
      break
    }
  }
  return (
    <strong className="text-body-medium inline-block whitespace-nowrap rounded-xl py-3">
      {icon}
      {text}
    </strong>
  )
}
