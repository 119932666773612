import type { MeetingCubit } from '@breakoutlearning/firebase-repository/cubits/MeetingCubit'
import { FullPageSpinner } from 'components/Spinner'
import { useDetailedMediaPermissionInfo } from 'hooks/av-test'
import { DeviceConfigurationStage } from './AvCheck/DeviceConfigurationStage'
import { PermissionsDeniedStage } from './AvCheck/PermissionsDeniedStage'
import { PermissionsPromptStage } from './AvCheck/PermissionsPromptStage'

export function AvCheck({ meeting }: { meeting: MeetingCubit }) {
  const { permissionState, videoPermissionState, audioPermissionState } =
    useDetailedMediaPermissionInfo()

  if (permissionState === 'initializing') {
    return <FullPageSpinner />
  }

  if (permissionState === 'waiting') {
    return <PermissionsPromptStage />
  }

  if (permissionState === 'denied' || permissionState === 'NotAllowed') {
    return <PermissionsDeniedStage />
  }

  return (
    <DeviceConfigurationStage
      meeting={meeting}
      permissionState={permissionState}
      audioPermissionState={audioPermissionState}
      videoPermissionState={videoPermissionState}
    />
  )
}
