import classNames from 'classnames'
import { inChrome, inFirefox, inSafari } from 'config/browser'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export function PermissionsPromptStage() {
  const { t, i18n } = useTranslation()
  const prompt = useMemo(() => {
    let key = 'unknown'

    if (inChrome) key = 'chrome'
    if (inSafari) key = 'safari'
    if (inFirefox) key = 'firefox'
    const resources = i18n.getResourceBundle(i18n.language, 'translation')
    try {
      let found = resources.avcheck.permissions_initial_step[key] as
        | string
        | undefined
      if (!found) {
        found = resources.avcheck.permissions_initial_step.generic as
          | string
          | undefined
      }
      return found || ''
    } catch (e) {
      return ''
    }
  }, [i18n])

  return (
    <div className="av-check h-full w-full rounded-2xl">
      <div className="relative h-full p-10">
        <img
          className={classNames('absolute -left-96 -top-96 z-10 w-[250px]', {
            '!left-[150px] !top-[150px]': inChrome,
            '!left-[360px] !top-[200px]': inFirefox,
            'safari !left-[50%] !top-[50%] -ml-[400px] -mt-[150px]': inSafari,
          })}
          src="/assets/images/avcheck-arrow.png"
        />
        <div className="-between flex h-full flex-col">
          <div className="flex-0">
            <img
              className="dark:invert"
              src="/assets/images/logo_large_trimmed.png"
              width={161}
            />
          </div>
          <div className="flex-1" />
          <div className="flex-0 z-40 max-w-[400px]">
            <div className="text-display-medium mb-2">
              {t('avcheck.permissions_headline')}
            </div>
            <div className="text-body-large">{prompt}</div>
            {/* <div className="text-body-large">
              It looks like you haven&apos;t enabled access to your camera and
              microphone. To join the session fully and interact with others,
              you&apos;ll need to enable these permissions.
            </div>
            <div>
              <ul className="list-disc pl-5">
                <li>
                  Click on Settings icon next to the URL in your Browser
                </li>
                <li>Click reset permissions</li>
              </ul>
            </div> */}
          </div>
          {!inSafari && <div className="flex-1" />}
        </div>
      </div>
    </div>
  )
}
