import classNames from 'classnames'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { inChrome, inFirefox, inSafari } from 'config/browser'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

export function PermissionsDeniedStage() {
  const { t, i18n } = useTranslation()
  const steps = useMemo(() => {
    let key = 'unknown'
    if (inChrome) key = 'chrome'
    if (inSafari) key = 'safari'
    if (inFirefox) key = 'firefox'
    const resources = i18n.getResourceBundle(i18n.language, 'translation')
    try {
      const found = resources.avcheck.permissions_steps[key] as string[]
      return found || []
    } catch (e) {
      return []
    }
  }, [i18n])

  return (
    <div className="av-check h-full w-full rounded-2xl">
      <div className="relative h-full">
        <img
          className={classNames('absolute -left-96 -top-96 z-10 w-[250px]', {
            '!left-[0px] !top-[-50px]': inChrome,
            '!left-[11%] !top-[-50px] lg:!left-[170px]': inFirefox,
          })}
          src="/assets/images/avcheck-arrow.png"
        />
        <div className="absolute inset-0 z-50 flex h-full flex-col overflow-auto p-10">
          <div className="flex-0">
            <img
              className="z-50 dark:invert"
              src="/assets/images/logo_large_trimmed.png"
              width={161}
            />
          </div>
          <div className="flex-1" />
          <div className="flex-0 z-40 max-w-[400px]">
            <div className="text-display-medium mb-2">
              {t('avcheck.permissions_headline')}
            </div>
            <div className="text-body-large">
              <p>{t('avcheck.permissions_denied_description')}</p>
              {steps.length > 0 && (
                <div>
                  <ol className="mt-2 list-decimal pl-5">
                    {steps.map((step, index) => (
                      <li className="mb-2" key={index}>
                        <Trans
                          i18nKey="myKey" // optional -> fallbacks to defaults if not provided
                          defaults={step}
                          values={{ what: 'world' }}
                          components={{ italic: <i />, bold: <strong /> }}
                        />
                      </li>
                    ))}
                  </ol>
                  <BreakoutButton
                    onClick={() => {
                      window.location.reload()
                    }}
                  >
                    {t('avcheck.re_run_permissions_check')}
                  </BreakoutButton>
                </div>
              )}
            </div>
          </div>
          {<div className="flex-1" />}
        </div>
      </div>
    </div>
  )
}
