import type { SectionState } from '@breakoutlearning/firebase-repository/types'
import { BreakoutCard } from 'components/design-system/BreakoutCard'
import { BreakoutPill } from 'components/design-system/BreakoutPill'
import { useTranslation } from 'react-i18next'
import { BreakoutTooltip } from '../BreakoutTooltip'

export function InstructorSectionCard({
  className,
  sectionName,
  userCount,
  caseCount,
  state,
  onClick,
  testId,
  isSharedSection = false,
  promotionNames,
}: {
  className: string
  sectionName: string
  userCount?: number
  caseCount?: number
  state: SectionState
  onClick?: () => void
  testId?: string
  isSharedSection?: boolean
  promotionNames?: string[]
}) {
  const { t } = useTranslation()
  return (
    <BreakoutCard data-testid={testId} onClick={onClick}>
      <BreakoutCard.Header>
        <div className="flex flex-row items-center justify-between">
          <strong className="text-label-medium text-grey-text">
            {isSharedSection ? t('instructor_classes.demo') : ''}
          </strong>
          <BreakoutPill.SectionState kind="primary" state={state} />
        </div>
      </BreakoutCard.Header>
      <BreakoutCard.Body>
        <div className="w-full">
          <h2 className="text-title-large line-clamp-2 text-on-surface">
            {className}
          </h2>
          <p className="text-body-medium line-clamp-1 text-on-surface-var">
            {sectionName}
          </p>
        </div>
        {promotionNames?.length === 1 && (
          <BreakoutPill
            className="mt-1 !block max-w-[90%] truncate"
            label={promotionNames[0]}
            kind="primary"
          />
        )}
        {promotionNames && promotionNames.length > 1 && (
          <BreakoutTooltip
            className="whitespace-pre-line"
            content={promotionNames.join('\n')}
          >
            <span>
              <BreakoutPill
                className="mt-1 !block "
                label={t('instructor_library.promotions_applied', {
                  count: promotionNames.length,
                })}
                kind="primary"
              />
            </span>
          </BreakoutTooltip>
        )}
      </BreakoutCard.Body>
      <BreakoutCard.Footer>
        <div className="grid w-full flex-none grid-cols-2 flex-row justify-between gap-5">
          <CountBox
            label={t('instructor_library.students')}
            count={userCount}
          />
          <CountBox
            label={t('instructor_library.experiences')}
            count={caseCount}
          />
        </div>
      </BreakoutCard.Footer>
    </BreakoutCard>
  )
}

function CountBox({
  count,
  label,
}: {
  count: number | undefined
  label: string
}) {
  return (
    <div className="flex flex-col text-center">
      <div className="text-title-large text-on-surface">
        {count === undefined ? '...' : count}
      </div>
      <div className="text-body-medium text-on-surface-var">{label}</div>
    </div>
  )
}
