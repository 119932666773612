import type { AssignmentState } from '@breakoutlearning/firebase-repository/types'
import { BreakoutCard } from 'components/design-system/BreakoutCard'
import { BreakoutPill } from 'components/design-system/BreakoutPill'
import { DateTime } from 'luxon'

export function InstructorAssignmentCard({
  imageUrl,
  title,
  subtitle,
  state,
  expiresAt,
  onClick,
  testId,
}: {
  imageUrl?: string
  title: string
  subtitle: string
  state: AssignmentState
  expiresAt?: DateTime
  onClick?: () => void
  testId?: string
}) {
  return (
    <BreakoutCard data-testid={testId} onClick={onClick}>
      <BreakoutCard.Header className="">
        <BreakoutPill.AssignmentState kind="primary" state={state} />
      </BreakoutCard.Header>
      <BreakoutCard.Body>
        {imageUrl && (
          <div className="flex h-[50px] w-[50px] flex-col items-center justify-center ">
            <img
              aria-hidden
              alt={title}
              src={imageUrl}
              className="inline max-h-[30px] max-w-[30px]"
            />
          </div>
        )}
        <h2 className="text-body-medium line-clamp-1 text-on-surface-var">
          {title}
        </h2>
        <h2 className="text-title-large line-clamp-2 text-on-surface">
          {subtitle}
        </h2>
      </BreakoutCard.Body>
      <BreakoutCard.Footer>
        <div className="text-body-small text-on-surface-var">Deadline</div>
        <div className="text-label-medium  text-on-surface">
          {expiresAt?.toLocaleString(DateTime.DATE_FULL)}
        </div>
      </BreakoutCard.Footer>
    </BreakoutCard>
  )
}
