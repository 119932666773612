import type { MeetingCubit } from '@breakoutlearning/firebase-repository/cubits/MeetingCubit'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { CheckIcon } from 'components/icons/Check'
import { ClockIcon } from 'components/icons/Clock'
import { HandIcon } from 'components/icons/Hand'
import { Microphone } from 'components/icons/Microphone'
import { PeopleGroup } from 'components/icons/PeopleGroup'
import { XSmallIcon } from 'components/icons/XSmall'
import type { DetailedPermissionState } from 'hooks/av-test'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { lazy, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const NewAvTest = lazy(() => import('pages/av-test/NewAvTest'))

const PROMPT_TIMEOUT = 2000

export const DeviceConfigurationStage = observer(
  function DeviceConfigurationStage({
    meeting,
    permissionState,
    audioPermissionState,
    videoPermissionState,
  }: {
    meeting: MeetingCubit
    permissionState: DetailedPermissionState
    audioPermissionState: DetailedPermissionState
    videoPermissionState: DetailedPermissionState
  }) {
    const { t } = useTranslation()

    const [joining, setJoining] = useState(false)

    const [audioCaptured, setAudioCaptured] = useState(false)
    const [videoCaptured, setVideoCaptured] = useState(false)
    const [showSpeechPrompt, setShowSpeechPrompt] = useState(false)
    const [showMovementPrompt, setShowMovementPrompt] = useState(false)
    const speechTimeout = useRef<NodeJS.Timeout | null>(null)
    const movementTimeout = useRef<NodeJS.Timeout | null>(null)

    const clearSpeechPrompt = () => {
      setShowSpeechPrompt(false)
      if (speechTimeout.current) clearTimeout(speechTimeout.current)
    }

    const clearMovementPrompt = () => {
      setShowMovementPrompt(false)
      if (movementTimeout.current) clearTimeout(movementTimeout.current)
    }

    useEffect(() => {
      if (audioCaptured) return
      if (permissionState !== 'granted') return
      speechTimeout.current = setTimeout(() => {
        setShowSpeechPrompt(true)
      }, PROMPT_TIMEOUT)
      return () => {
        clearSpeechPrompt()
      }
    }, [permissionState, audioCaptured])

    useEffect(() => {
      if (videoCaptured) return
      if (permissionState !== 'granted') return
      movementTimeout.current = setTimeout(() => {
        setShowMovementPrompt(true)
      }, PROMPT_TIMEOUT)
      return () => {
        clearMovementPrompt()
      }
    }, [permissionState, videoCaptured])

    const canJoin = useMemo(() => {
      return permissionState === 'granted'
      // return permissionState === 'granted' && audioCaptured && videoCaptured
    }, [permissionState])

    const overlay = useMemo(() => {
      if (!showSpeechPrompt && !showMovementPrompt) return undefined
      if (showSpeechPrompt) {
        return (
          <div className="absolute z-50 flex h-full w-full flex-col items-center justify-center rounded-3xl bg-black bg-opacity-50 from-transparent to-surface">
            <div className="text-label-large text-center text-core-on-primary">
              <Microphone className="mx-auto mb-4" size={128} />
              <div className="max-w-[260px]">
                {t('avcheck.microphone_prompt')}
              </div>
            </div>
          </div>
        )
      }
      if (showMovementPrompt) {
        return (
          <div className="absolute z-50 flex h-full w-full flex-col items-center justify-center rounded-3xl bg-black bg-opacity-50 from-transparent to-surface">
            <div className="text-label-large text-center text-core-on-primary">
              <HandIcon className="mx-auto mb-4 rotate-45" size={128} />
              <div className="max-w-[260px]">{t('avcheck.camera_prompt')}</div>
            </div>
          </div>
        )
      }
    }, [t, showMovementPrompt, showSpeechPrompt])

    return (
      <div className="flex h-full w-full flex-col overflow-hidden bg-surface-dim lg:items-center lg:justify-center">
        <div className="m-auto block h-full  w-full grid-cols-2 gap-3 space-y-4 overflow-y-auto lg:grid lg:space-y-0">
          <div className="flex flex-col justify-between gap-5 rounded-3xl bg-surface-bright px-14 py-10">
            <div>
              <div>
                <img src="/assets/images/logo_large_trimmed.png" width={160} />
              </div>
            </div>
            <div>
              <div className="text-headline-large text-on-surface">
                {t('avcheck.welcome_text')}
              </div>

              <div className="mt-3 flex gap-2">
                <div className="text-body-medium flex w-full flex-wrap items-center justify-start rounded-2xl bg-surface px-4 py-4 text-on-surface">
                  <ClockIcon
                    size={20}
                    className="mr-2 stroke-fixed-accent-color"
                  />{' '}
                  {t('avcheck.session_date')}:
                  <span className="text-label-medium pl-1">
                    {meeting.roomState.scheduledAtDate?.toLocaleString(
                      DateTime.DATETIME_MED
                    )}
                  </span>
                </div>
                <div className="text-body-medium flex w-full flex-wrap items-center justify-start rounded-2xl bg-surface px-4 py-4 text-on-surface">
                  <PeopleGroup
                    size={20}
                    className="mr-2 stroke-fixed-accent-color"
                  />{' '}
                  {t('avcheck.group')}:
                  <span className="text-label-medium pl-1">
                    {meeting.roomState.roomStateName}
                  </span>
                </div>
              </div>
              <div className="mt-4">
                <h2 className="text-headline-small mb-1">
                  {t('avcheck.av_check_title')}
                </h2>
                <p className="text-body-large mb-4">
                  {t('avcheck.av_check_description')}
                </p>
                <Status
                  audioPermissionState={audioPermissionState}
                  videoPermissionState={videoPermissionState}
                  audioCaptured={audioCaptured}
                  videoCaptured={videoCaptured}
                />
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div className="text-body-large flex-grow pr-4 text-on-surface"></div>
              <div className="flex-shrink-0">
                <BreakoutButton
                  data-testid="join-meeting-button"
                  disabled={!canJoin || joining}
                  loading={joining}
                  kind="primary"
                  size="large"
                  type="submit"
                  onClick={() => {
                    setJoining(true)
                    meeting.setAvCheckPassed(true)
                  }}
                >
                  {t('demo.join_meeting')}
                </BreakoutButton>
              </div>
            </div>
          </div>
          <div className="rounded-3xl bg-surface-bright p-10">
            {permissionState === 'granted' && (
              <div className="mx-auto h-full">
                <NewAvTest
                  onAudioCaptured={() => {
                    setAudioCaptured(true)
                    clearSpeechPrompt()
                  }}
                  onMotionCaptured={() => {
                    setVideoCaptured(true)
                    clearMovementPrompt()
                  }}
                  onAudioDeviceChange={() => {
                    setAudioCaptured(false)
                  }}
                  onVideoDeviceChange={() => {
                    setVideoCaptured(false)
                  }}
                  overlay={overlay}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
)

function CheckOrX({ value }: { value: boolean }) {
  return value ? (
    <CheckIcon size={20} className="ml-2" stroke="green" />
  ) : (
    <XSmallIcon size={24} className="ml-2" stroke="red" />
  )
}

function Status({
  audioPermissionState,
  videoPermissionState,
  audioCaptured,
  videoCaptured,
}: {
  audioPermissionState: DetailedPermissionState
  videoPermissionState: DetailedPermissionState
  audioCaptured: boolean
  videoCaptured: boolean
}) {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-3 rounded-2xl bg-surface p-4">
      <div>
        <div className="text-title-small mb-2 px-2">
          {t('avcheck.permissions')}
        </div>
        <div className="rounded-2xl bg-surface-bright p-3">
          <table role="presentation" className="w-full">
            <tbody>
              <tr>
                <td className="text-body-medium w-[50%] min-w-[150px] py-1 text-left text-on-surface-var">
                  {t('avcheck.audio_permissions')}
                </td>
                <td className="pr-10">
                  <CheckOrX value={audioPermissionState === 'granted'} />
                </td>
              </tr>
              <tr>
                <td className="text-body-medium w-[30%] py-1 text-left text-on-surface-var">
                  {t('avcheck.video_permissions')}
                </td>
                <td>
                  <CheckOrX value={videoPermissionState === 'granted'} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <div className="text-title-small mb-2 px-2">
          {t('avcheck.devices_captured')}
        </div>
        <div className="rounded-2xl bg-surface-bright p-3">
          <table role="presentation" className="w-full">
            <tbody>
              <tr>
                <td className="text-body-medium w-[50%] min-w-[150px] py-1 text-left text-on-surface-var">
                  {t('avcheck.audio_captured')}
                </td>
                <td className="pr-10">
                  {audioPermissionState === 'granted' ? (
                    <CheckOrX value={audioCaptured} />
                  ) : (
                    <Problem
                      type="audio"
                      permissionState={audioPermissionState}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td className="text-body-medium w-[30%] py-1 text-left text-on-surface-var">
                  {t('avcheck.video_captured')}
                </td>
                <td>
                  {videoPermissionState === 'granted' ? (
                    <CheckOrX value={videoCaptured} />
                  ) : (
                    <Problem
                      type="video"
                      permissionState={videoPermissionState}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

function Problem({
  type,
  permissionState,
}: {
  type: 'audio' | 'video'
  permissionState: DetailedPermissionState
}) {
  const { t } = useTranslation()
  if (permissionState === 'initializing') return null
  if (permissionState === 'waiting') return null
  if (permissionState === 'granted') return null

  let contents: React.ReactNode = null
  switch (permissionState) {
    case 'denied':
      contents = (
        <div>
          We were not able to access your {type} device. Please check your
          device.
        </div>
      )
      break
    case 'NotAllowed':
      contents = <div>You have denied access to your {type} device.</div>
      break
    case 'NotReadable':
      contents = (
        <div>
          <div className="text-label-medium mb-1">
            {t('avcheck.device_not_readable_header', { type })}
          </div>
          <div className="text-body-medium">
            {t('avcheck.device_not_readable_description', { type })}
          </div>
        </div>
      )
      break
    case 'Overconstrained':
      contents = <div>Your browser is unable to access your {type} device</div>
      break
    case 'Security':
      contents = <div>You have denied access to your {type}</div>
      break
    default:
      break
  }

  if (!contents) return null

  return (
    <div className="text-label-medium mx-auto w-fit rounded-2xl bg-red-100 p-3">
      {contents}
    </div>
  )
}
