import { InstructorSlideDeckCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSlideDeckCubit'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { ChevronLeft } from 'components/icons/ChevronLeft'
import { PlusIcon } from 'components/icons/Plus'
import { ImpersonationInfo } from 'components/ImpersonationInfo'
import { useRepository } from 'hooks/auth'
import { useCubitBuilder } from 'hooks/cubits'
import { useDialogs } from 'hooks/dialogs'
import { useRootStore } from 'hooks/rootStore'
import {
  MainPane,
  Header,
  Headline,
  Actions,
  Contents,
} from 'pages/layout/TwoColumn'
import { useTranslation } from 'react-i18next'
import { AssignClassDialog } from './AssignClassDialog'
import { observer } from 'mobx-react-lite'
import {
  InstructorSlideDeckCubitProvider,
  useInstructorSlideDeckCubit,
} from 'hooks/cubits/instructorSlideDeck'
import classNames from 'classnames'
import { useMemo } from 'react'
import { LinkIcon } from 'components/icons/Link'
import { GoogleStorageImage } from 'components/GoogleStorageImage'
import {
  SlideDeckMaterialWorkType,
  SlideDeckReferenceType,
} from '@breakoutlearning/firebase-repository/types'
import { CourseMaterialButton } from 'components/slide-deck-material-components/CourseMaterialListItem'
import { ShowExperienceDialog } from 'pages/student/assignment/dialogs/ShowExperienceDialog'
import { Spinner } from 'components/Spinner'
import { RevampedSlideDeckTrailerThumbnail } from '../shared/RevampedSlideDeckCard'
import { AddSectionDialog } from '../classes/AddSectionDialog'
import { useBreakoutUser } from 'hooks/profile'

export const InstructorSlideDeckPage = observer(
  function InstructorSlideDeckPage({ slideDeckId }: { slideDeckId: string }) {
    const { t } = useTranslation()
    const rootStore = useRootStore()
    const user = useBreakoutUser()
    const instructorUserId = rootStore.impersonatedUserId
    const repository = useRepository()
    const paramCatalogId = rootStore.router.queryParams?.catalogId
    const catalogId =
      typeof paramCatalogId === 'string' ? paramCatalogId : undefined
    const cubit = useCubitBuilder(
      () =>
        new InstructorSlideDeckCubit(
          repository,
          slideDeckId,
          instructorUserId,
          catalogId
        ),
      [repository, slideDeckId, catalogId]
    )
    const { showDialog } = useDialogs()
    const queryParams = rootStore.router.queryParams

    return (
      <MainPane>
        <Header>
          <Headline
            className="text-display-medium font-display min-w-[35%] cursor-pointer truncate"
            onClick={() => {
              if (
                typeof queryParams?.teachingPlanId === 'string' &&
                typeof queryParams?.catalogId === 'string'
              ) {
                rootStore.navigateToTeachingPlanPage(
                  queryParams?.catalogId,
                  queryParams?.teachingPlanId,
                  {
                    sectionId: queryParams?.sectionId,
                  }
                )
                return
              }
              rootStore.navigateTo('instructorLibrary', undefined, {
                sectionId: queryParams?.sectionId,
                catalogId: queryParams?.catalogId,
              })
            }}
          >
            <div className="flex flex-row items-center">
              <div className="pr-1">
                <ChevronLeft size={24} />
              </div>
              {cubit.slideDeck.data.slideDeckName}
              <ImpersonationInfo />
            </div>
          </Headline>
          <Actions>
            {/*
                only show the assign class dialog if catalogId is set and the deck is not archived
                also, wait for sections to load before showing the dialog so that we know which dialog
                to show
             */}
            {!!queryParams?.catalogId &&
              !cubit.slideDeck.isArchived &&
              cubit.sections.isLoaded && (
                <BreakoutButton
                  data-testid="add-to-class-button"
                  kind="primary"
                  size="large"
                  icon={<PlusIcon size={14} />}
                  onClick={() => {
                    const showAssignClassDialog = () => {
                      showDialog(({ remove }) => (
                        <AssignClassDialog cubit={cubit} onCreate={remove} />
                      ))
                    }
                    // For TA's, we never show add to class dialog
                    if (user.isTA) {
                      showAssignClassDialog()
                      return
                    }
                    if (cubit.sections.models.length === 0) {
                      return showDialog(({ remove }) => (
                        <AddSectionDialog
                          createSection={cubit.createSection}
                          onCreate={(sectionId) => {
                            if (!sectionId) return

                            rootStore.updateQueryParams({ sectionId })

                            remove()
                            showAssignClassDialog()
                          }}
                          instructorUserId={rootStore.impersonatedUserId}
                          redeemPromotions={cubit.redeemPromotions}
                          title={t('instructor_library.class_details')}
                        />
                      ))
                    }

                    showAssignClassDialog()
                  }}
                >
                  {t('instructor_library.add_to_class')}
                </BreakoutButton>
              )}
          </Actions>
        </Header>

        <Contents className="h-full md:overflow-hidden">
          <InstructorSlideDeckCubitProvider value={cubit}>
            <Content />
          </InstructorSlideDeckCubitProvider>
        </Contents>
      </MainPane>
    )
  }
)

const Content = observer(function Content() {
  const cubit = useInstructorSlideDeckCubit()
  const { t } = useTranslation()
  const { showDialog } = useDialogs()

  const materials = cubit.materials.models
  const slideDeck = cubit.slideDeck

  const instructorMaterials = useMemo(() => {
    return materials.filter(
      (material) =>
        material.data.viewableByInstructor && !material.data.viewableByStudent
    )
  }, [materials])

  const preWorkMaterials = useMemo(() => {
    return materials.filter(
      (material) =>
        material.data.viewableByStudent &&
        material.data.materialWorkType === SlideDeckMaterialWorkType.preWork
    )
  }, [materials])

  const otherMaterials = useMemo(() => {
    return materials.filter(
      (material) =>
        material.data.viewableByStudent &&
        material.data.materialWorkType !== SlideDeckMaterialWorkType.preWork
    )
  }, [materials])

  const preQuizQuestions = useMemo(() => {
    return cubit.questionsSorted.filter(
      (question) => question.data.slideId === 'pre-meeting-quiz'
    )
  }, [cubit.questionsSorted])

  if (
    !cubit.materials.isLoaded ||
    !cubit.slideDeck.isLoaded ||
    !cubit.authors.isLoaded
  ) {
    return (
      <div className="mt-20 flex w-full justify-center">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="h-full overflow-hidden">
      <div className={classNames('h-full overflow-y-auto pr-1')}>
        <div>
          <RevampedSlideDeckTrailerThumbnail
            slideDeckMaterials={materials}
            squareThumbnail={false}
          />
        </div>
        <div tabIndex={0}>
          <div className="mt-3 flex items-center justify-between">
            <div className="flex items-center">
              {slideDeck.data.slideDeckImageURL && (
                <img
                  src={slideDeck.data.slideDeckImageURL}
                  className="max-h-[65px] max-w-[65px] p-1"
                  aria-hidden
                  alt={slideDeck.data.slideDeckName}
                />
              )}
              <div className="ml-2">
                <h1 className="text-headline-large text-on-surface">
                  {slideDeck.data.slideDeckName}
                </h1>
                <p className="text-label-large text-on-surface-var">
                  {slideDeck.data.slideDeckTeaser}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="text-label-medium flex h-[52px] w-[152px] select-none items-center justify-center rounded-2xl bg-core-secondary text-core-on-secondary ">
                {t('instructor_library.price', {
                  count: slideDeck.data.slideDeckPrice,
                  price: slideDeck.priceInDollars,
                })}
              </div>
            </div>
          </div>

          {cubit.references.models.length > 0 &&
            cubit.references.models.map((reference) => (
              <div
                key={reference.id}
                className="mt-3 flex items-center rounded-2xl bg-surface px-4 py-3"
              >
                <div
                  className={classNames(
                    'flex items-center divide-x-2',
                    reference.data.referenceType ===
                      SlideDeckReferenceType.alternativeTo
                      ? 'divide-fixed-accent-color'
                      : 'divide-on-surface-disabled'
                  )}
                >
                  <h2 className="text-label-large mr-3 w-[100px] text-on-surface">
                    {reference.data.referenceType ===
                    SlideDeckReferenceType.alternativeTo
                      ? t('instructor_library.alternative_to')
                      : t('instructor_library.based_on')}
                  </h2>
                  <div className="pl-3">
                    <p className="text-title-medium  italic text-on-surface">
                      {reference.data.referenceTitle}
                    </p>
                    <p className="text-body-large text-on-surface-var">
                      {reference.data.referenceAuthor},{' '}
                      {reference.data.referencePublisher},{' '}
                      {reference.getFormattedPublishedAt()}
                    </p>
                  </div>
                </div>
                <div className="flex flex-grow items-center justify-end">
                  <LinkIcon
                    size={16}
                    className="cursor-pointer"
                    onClick={() => {
                      window.open(reference.data.referenceURL, '_blank')
                    }}
                  />
                </div>
              </div>
            ))}

          <p className="text-body-large mt-3 text-on-surface-var">
            {slideDeck.data.slideDeckDescription}
          </p>

          {cubit.authors.models.length > 0 && (
            <div className="mt-3">
              <h3 className="text-title-large text-on-surface">
                {t('instructor_library.authors')}
              </h3>
              <div className="space-y-3">
                {cubit.authors.models.map((author) => (
                  <div
                    className="mt-1 flex flex-row items-center gap-2"
                    key={author.id}
                  >
                    {author.data.authorImageURL && (
                      <div>
                        <GoogleStorageImage
                          src={author.data.authorImageURL}
                          className="aspect-square max-h-[30px] max-w-[30px] rounded-full border-1 border-surface"
                          namespace="instructor-library"
                          alt={author.data.authorName}
                        />
                      </div>
                    )}
                    <div>
                      <div className="text-title-medium line-clamp-1 text-on-surface">
                        {author.data.authorName}
                      </div>
                      <div className="text-body-medium line-clamp-1 text-on-surface-var">
                        {author.data.authorTitle}—
                        {author.data.authorInstitution}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {cubit.slideDeck.data.slideDeckLearningObjectives.length > 0 && (
            <div className="mt-3">
              <h3 className="text-title-large text-on-surface">
                {t('instructor_library.learning_objectives')}
              </h3>
              <ul className="list-disc pl-5 marker:text-on-surface-var">
                {cubit.slideDeck.data.slideDeckLearningObjectives.map(
                  (objective, index) => (
                    <li
                      key={objective}
                      className={classNames(index !== 0 && '-mt-1.5')}
                    >
                      <div className="mt-1 flex flex-row items-center gap-2">
                        <div className="text-body-large text-on-surface-var">
                          {objective}
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          )}

          {cubit.rubrics.length > 0 && (
            <div className="mt-3">
              <h3 className="text-title-large text-on-surface">
                {t('instructor_library.rubrics')}
              </h3>
              <ul className="list-disc pl-5 marker:text-on-surface-var">
                {cubit.rubrics.models.map((rubric, index) => (
                  <li
                    key={rubric.id}
                    className={classNames(index !== 0 && '-mt-1.5')}
                  >
                    <div className="mt-1 flex flex-row items-center gap-2">
                      <div className="text-body-large text-on-surface-var">
                        {rubric.data.rubricDescription}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {instructorMaterials.length > 0 && (
            <div className="mt-3">
              <h3 className="text-title-large text-on-surface">
                {t('instructor_library.instructor_materials')}
              </h3>
              <div className="mr-1 mt-2 grid grid-cols-2 gap-x-3 gap-y-2">
                {instructorMaterials.map((material) => (
                  <div
                    key={material.id}
                    className="flex h-[52px] items-center justify-between rounded-2xl bg-surface px-4"
                  >
                    <p className="text-label-medium line-clamp-1 text-on-surface">
                      {material.data.materialName}
                    </p>
                    <CourseMaterialButton
                      material={material}
                      slideDeckId={cubit.slideDeckId}
                      slideDeckName={cubit.slideDeck.data.slideDeckName}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div tabIndex={0}>
          <div className="relative flex items-center py-4">
            <div className="flex-grow border-t-1 border-fixed-accent-color"></div>
            <strong className="text-title-medium mx-4 flex-shrink text-on-surface">
              {t('instructor_library.student_experience')}
            </strong>
            <div className="flex-grow border-t-1 border-fixed-accent-color"></div>
          </div>

          {(preWorkMaterials.length > 0 || preQuizQuestions.length > 0) && (
            <div className="rounded-[20px] border border-outline-variant px-8 py-5">
              <h1 className="text-headline-medium text-on-surface">
                {t('instructor_library.pre_work')}
              </h1>
              <div className="mt-3 grid grid-cols-2 gap-5">
                {preWorkMaterials.length > 0 && (
                  <div>
                    <h2 className="text-title-medium text-on-surface">
                      {t('instructor_library.pre_work_materials')}
                    </h2>
                    <div className="mt-1 space-y-2">
                      {preWorkMaterials.map((material) => (
                        <div
                          key={material.id}
                          className="flex h-[52px] items-center justify-between rounded-2xl bg-surface px-4"
                        >
                          <p className="text-label-medium line-clamp-1 text-on-surface">
                            {material.data.materialName}
                          </p>
                          <CourseMaterialButton
                            material={material}
                            slideDeckId={cubit.slideDeckId}
                            slideDeckName={cubit.slideDeck.data.slideDeckName}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {preQuizQuestions.length > 0 && (
                  <div>
                    <h2 className="text-title-medium text-on-surface">
                      {t('instructor_library.pre_quiz')}
                    </h2>
                    <ul className="mt-1 list-disc space-y-2 pl-4 marker:text-on-surface-var">
                      {preQuizQuestions.map((question, index) => (
                        <li
                          key={question.id}
                          className={classNames(
                            'text-body-large text-on-surface-var',
                            index !== 0 && '!mt-0.5'
                          )}
                        >
                          {question.data.question}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}

          {otherMaterials.length > 0 && (
            <div className="mt-3 rounded-[20px] border border-outline-variant px-8 py-5">
              <h1 className="text-headline-medium text-on-surface">
                {t('instructor_library.other_materials')}
              </h1>
              <div className="mt-3">
                <div className="mt-2 grid grid-cols-2 gap-4">
                  {otherMaterials.map((material) => (
                    <div
                      key={material.id}
                      className="flex h-[52px] items-center justify-between rounded-2xl bg-surface px-4"
                    >
                      <p className="text-label-medium line-clamp-1 text-on-surface">
                        {material.data.materialName}
                      </p>
                      <CourseMaterialButton
                        material={material}
                        slideDeckId={cubit.slideDeckId}
                        slideDeckName={cubit.slideDeck.data.slideDeckName}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          <div className="mt-3 rounded-[20px] border border-outline-variant px-8 py-9">
            <div className="flex items-center justify-between">
              <div>
                <h1 className="text-headline-medium text-on-surface">
                  {t('instructor_library.main_experience')}
                </h1>
                <p className="text-body-large mt-1 text-on-surface-var">
                  {t('instructor_library.main_experience_subtitle')}
                </p>
              </div>
              <div>
                <BreakoutButton
                  size="large"
                  onClick={() => {
                    showDialog(() => (
                      <ShowExperienceDialog slideDeckId={cubit.slideDeckId} />
                    ))
                  }}
                >
                  {t('instructor_library.view_student_experience')}
                </BreakoutButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
