import { useRootStore } from 'hooks/rootStore'
import { reaction } from 'mobx'
import { useEffect } from 'react'
import { useBreakoutUser } from 'hooks/profile'
import { useRepository } from 'hooks/auth'
import { UserProfileRole } from '@breakoutlearning/firebase-repository/types'
import { useDialogs } from 'hooks/dialogs'
import { PromotionAcceptedDialog } from 'components/PromotionAcceptedDialog'

export const AuthedRedirectHandler = () => {
  const store = useRootStore()
  const user = useBreakoutUser()
  const repository = useRepository()

  const redirectExceptions = ['avTest', 'onboarding', 'logout', 'meeting']
  const { showDialog, anyDialogsOpen } = useDialogs()

  useEffect(() => {
    // When logout is detected, navigate to logout page, unless we're already there
    // Why do we need it? When a user has multiple tabs open, and logs out in one tab,
    // the other tabs receive a onAuthStateChanged event, and log out, however by not leaving
    // the page they are currently on, they leave the streams as active, which then throw
    // a ton of permission errors, which are bogus - we just logged out.
    //
    // So do alleviate that, when logout is detected, we navigate to logout page, which
    // which makes the previous page clean up and should prevent the wave of permission errors.
    const listener = () => {
      if (store.router.currentRoute?.title === 'logout') return
      store.navigateTo('logout')
    }
    repository.onLogout(listener)
    return () => repository.offLogout(listener)
  }, [repository, store])

  useEffect(() => {
    return reaction(
      () => ({
        shouldShowOnboarding: user.shouldShowOnboarding,
        userIsLoading: user.isLoading,
        currentRoute: store.router.currentRoute,
      }),
      (
        { shouldShowOnboarding, userIsLoading, currentRoute },
        prev,
        reaction
      ) => {
        // do nothing if we are on a demo page or logout
        if (
          currentRoute?.path.startsWith('/demo') ||
          currentRoute?.path.startsWith('/logout')
        ) {
          return
        }
        if (redirectExceptions.includes(store.router.currentRoute?.title || ''))
          return
        const route = 'onboarding'
        // if we are leaving onboarding, do nothing
        if (prev.currentRoute?.title === route) return
        const isOnboarding = store.router.currentRoute?.title === route
        if (shouldShowOnboarding && !isOnboarding) {
          store.navigateTo('onboarding')
        }
        if (!shouldShowOnboarding && !userIsLoading) {
          // dispose self onboarding complete
          reaction.dispose()
        }
      }
    )
  })

  useEffect(() => {
    return reaction(
      () => store.router.currentRoute,
      (route, _, reaction) => {
        // do nothing if we are on a demo page or logout
        if (
          route?.path.startsWith('/demo') ||
          route?.path.startsWith('/logout')
        ) {
          return
        }

        if (user.profile.isAnonymous) {
          const isValidRoute =
            route?.path.startsWith('/demo') ||
            route?.path.startsWith('/logout') ||
            route?.path.startsWith('/meeting')
          const isInvitationRoute = route?.path.startsWith('/invitation')
          if (isInvitationRoute) {
            const redirectURL = encodeURIComponent(window.location.href)
            return store.navigateTo('logout', undefined, { redirectURL })
          }
          if (!isValidRoute) return store.navigateTo('demoWelcome')
        }
        if (redirectExceptions.includes(route?.title || '')) return
        const avCheckEnabled = repository.featureFlags.data?.avCheckEnabled
        if (!avCheckEnabled && !localStorage.getItem('lk-user-choices')) {
          return store.navigateTo('avTest')
        }
        // dispose of self if lk user choices is set
        reaction.dispose()
      },
      { fireImmediately: true }
    )
  })

  useEffect(() => {
    return reaction(
      () => user.role,
      (role, prevRole) => {
        if (prevRole && role !== prevRole) store.navigateTo('home')
      },
      { fireImmediately: true }
    )
  })

  useEffect(() => {
    if (user.role !== UserProfileRole.instructor) return
    return reaction(
      () => ({
        loaded: user.userPromotions.isLoaded,
        models: user.userPromotions.models,
      }),
      (current, prev) => {
        // if any are false, abort, nothing to do
        if (!current || !prev) return
        if (!current.loaded || !prev.loaded) return

        if (current.models.length > prev.models.length) {
          // we have a new promo, find it
          const oldIdsLookup = prev.models.reduce(
            (acc, p) => {
              acc[p.id] = true
              return acc
            },
            {} as Record<string, boolean>
          )
          const newPromo = current.models.find(
            (promo) => !oldIdsLookup[promo.id]
          )
          if (newPromo) {
            // if no dialogs are open, show the "new promo" dialog
            if (!anyDialogsOpen()) {
              showDialog(() => <PromotionAcceptedDialog promotion={newPromo} />)
            }
          }
        }
      }
    )
  })

  return null
}
